<template>
  <div>
    <div class="d-flex justify-content-center col-12 text-center">
      <head-banner />
    </div>
    <div class="d-flex justify-content-center col-12 text-center">
      <add-here />
    </div>
    <b-row class="text-center">
      <b-col align-self="center" class="mb-2">
        <b-link to="/" class="brand-logo">
          <b-img
            src="@/assets/images/logo/newlogo1.svg"
            height="100"
            alt="RugFreeCoins"
          ></b-img>
        </b-link>
      </b-col>
    </b-row>

    <b-row class="match-height mb-1">
      <b-col lg="12">
        <h1 class="text-center">Audit coins</h1>
      </b-col>
    </b-row>

    <b-row class="match-height mb-2" v-if="!is_mobilesize">
      <b-col lg="5"></b-col>
      <b-col lg="2">
        <b-button @click="modalShows = true" variant="success">Audit Process</b-button>
      </b-col>
    </b-row>
    <b-row class="match-height mb-2" v-else>
      <div class="modal-button">
        <b-button @click="modalShows = true" variant="success">Audit Process</b-button>
      </div>
    </b-row>

    <audit-details :modalShows="modalShows" />

    <b-row class="match-height mb-1">
      <b-col lg="12">
        <crypto-table :table-data="cryptoDataAuditedCoins" :table_name="'audited'" />
      </b-col>
    </b-row>

    <b-row class="match-height mb-1">
      <b-col lg="12" class="text-center">
        <h2 class="text-center">TO AUDIT YOUR COIN</h2>
        <small>Mail to </small> <b>contact@rugfreecoins.com</b>
      </b-col>
    </b-row>

    <b-row class="text-center">
      <b-col align-self="center" class="mb-2">
        <b-alert variant="danger" show class="mb-0">
          <div class="alert-body">
            <feather-icon icon="InfoIcon" class="mr-50" />
            Do <b>never pay anyone for a audit on our platform</b>, unless you have
            received a confirmation email from this adress.
          </div>
        </b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BLink, BImg, BAlert, BButton } from "bootstrap-vue";
import CryptoTable from "../views/crypto/CryptoTable";
import AddHere from "@/@core/components/AddHere.vue";
import HeadBanner from "@/@core/components/carousel/HeadBanner.vue";
import AuditDetails from "./AuditDetails.vue";
import { mixinList } from "@/mixins/mixinList";

export default {
  mixins: [mixinList],
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BAlert,
    CryptoTable,
    AddHere,
    HeadBanner,
    AuditDetails,
    BButton,
  },
  data() {
    return {
      loading: false,
      open_model: false,
      modalShows: false,
    };
  },
  computed: {
    cryptoDataAuditedCoins() {
      return this.$store.state.crypto.cryptoDataAuditedCoins;
    },
    api_loading() {
      return this.$store.state.loaders.apiloading;
    },
  },
  methods: {},
  created() {
    if (!this.$store.state.crypto.clientIP) {
      this.$store.dispatch("FETCH_CLIENT_IP").then(() => {
        this.$store.dispatch("FETCH_AUDITED_CRYPTO_DATA", 20);
      });
    }
  },
};
</script>

<style>
.modal-button {
  margin-left: 38%;
}
</style>
