<template>
  <div>
    <b-modal
      id="modal-center"
      v-model="modalShows"
      ok-only
      ok-title="Ok"
      centered
      size="lg"
      hide-backdrop
      no-close-on-backdrop
      title="Audit process"
    >
      <b-row class="match-height mb-1">
        <b-col lg="12">
          <!-- <h2>Audit process</h2> -->
          <h4 class="pt-2">
            <b
              >Audits: For the projects which have not been launched. (We charge
              1500$ for the full process)</b
            >
          </h4>
          <p class="pl-2">
            <b>Step 1: </b>We test the contract first in the Testnet and our
            local environment to make sure everything's functioning as intended,
            and let the client know if we'd notice any issue with our
            recommendations to get them fixed.
          </p>
          <p class="pl-2">
            <b>Step 2: </b> If we would notice any functions that the owner has
            more privileges to manipulate in the contract. We would advise the
            client to get them changed.
          </p>
          <p class="pl-2">
            <b>Step 3: </b> If there are any reasonable reasons for having those
            functions, we would ask the client to dox themselves to us through a
            verification process of sending passport copies, live video calls,
            and other verification steps.
          </p>
          <li class="pl-4">
            If the client could pass us that information, we would put those
            functions under the informational section in the report.
          </li>
          <li class="pl-4">
            If the client fails to dox themselves to us, we would put those
            functions under the high-risk section in the report.
          </li>
          <li class="pl-4">
            Even though everything's passed, there can be instances we put those
            functions under the high-risk section for various reasons. We may
            change the status after two from the launch by doing some security
            checks.
          </li>
          <p class="pt-2 pl-2">
            <b>Step 4: </b> Once everything's sorted and fixed, we would ask the
            client to deploy the contract to the Mainnet and send us the link to
            complete the manual and automated audit tests under the RugFreeCoins
            audit criteria.
          </p>
          <p class="pl-2">
            <b>Step 5: </b> We will also do a project market research and
            project market feasibility study under criteria by allocating points
            to evaluate the potential to grow.
          </p>
          <p class="pl-2">
            <b>Step 6: </b> If everything's in order, we would publish the
            report in Github and the audited section of our platform with
            low-risk level status. If there are any high-risk functions, we set
            the status into DYOR, and we won't assure or take any responsibility
            for investments.
          </p>
          <div class="pl-2">
            <b-alert variant="danger" show class="mb-0">
              <div class="alert-body text-center">
                <feather-icon icon="InfoIcon" class="mr-50" />
                <b
                  >Please note that even though the audit security checks are
                  passed and the project is with a perfect use case, the Defi
                  market can surprise you at any time, and you may lose your
                  money. Please always Do Your Own Research and invest money you
                  can afford to lose.
                </b>
              </div>
            </b-alert>
          </div>

          <h4 class="pt-2">
            <b
              >Audits: For the projects already launched. (We charge 1000$ for
              the full process)</b
            >
          </h4>
          <p class="pt-2 pl-2">
            <b>Step 1: </b> RugFreeCoins will run manual and automated audit
            tests under the RugFreeCoins audit criteria. We check every single
            code line by line in the audit process and mention in the report
            with impact and our recommendations.
          </p>
          <p class="pl-2">
            <b>Step 2: </b> We will also do a project market research and
            project market feasibility study under criteria by allocating points
            to evaluate the potential to grow.
          </p>
          <p class="pl-2">
            <b>Step 3: </b> If everything's in order, we would publish the
            report in Github and the audited section of our platform with
            low-risk level status. If there are any high-risk functions, we set
            the status into DYOR, and we won't assure or take any responsibility
            for investments. We consider the liquidity lock period and details
            before deciding the risk level status.
          </p>
          <p class="pl-2">
            <b>The audit report criteria</b>
          </p>
          <b-row class="pl-4">
            <b-col md="6">
              <p>1. Background of the project</p>
              <p>2. Roadmap</p>
              <p>3. Tokenomics</p>
              <p>4. The core concept</p>
              <p>5. Target market</p>
              <p>6. Potential to grow with score points</p>
              <p>7. contract details</p>
              <p>8. Top token holders</p>
            </b-col>
            <b-col md="6">
              <p>9. Token distribution</p>
              <p>10. Contract interaction details</p>
              <p>11. Contract code function details</p>
              <p>12. Security issue checking status</p>
              <p>13. Issues found and impact.</p>
              <p>14. Owner privileges</p>
              <p>15. Audit conclusion</p>
              <p>16. Liquidity lock details.</p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { BRow, BCol, BAlert } from "bootstrap-vue";
export default {
  props: ['modalShows'],
  components: {
    BRow,
    BCol,
    BAlert,
  },
};
</script>